import TagManager from "react-gtm-module";

const TagManagerHelper = {
	dataLayer({ ...args }) {
		return TagManager.dataLayer({
			dataLayer: { ...args }
		});
	}
};

export default TagManagerHelper;
