import colors from "colors";
import { ComplexIconProps } from "./types";

export default function IcoArrowRightCircleBackground({
	fillColor = colors.neutral["low-pure-500"],
	contentColor = colors.neutral["high-pure-50"],
	size = "24"
}: ComplexIconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill={fillColor}
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width={size} height={size} rx="16" fill={fillColor} />
			<path
				d="M10.75 16L20.5 16M16 10.75L21.25 16L16 21.25"
				stroke={contentColor}
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
}
