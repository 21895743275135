import colors from "colors";
import { IconProps } from "./types";

export default function IcoMotorbike({
	color = colors.neutral["low-pure-500"],
	size = "16"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M3.09677 10.5C3.09677 11.325 3.675 12 4.5 12C5.325 12 6.09677 11.325 6.09677 10.5L6.07019 6.6278H3.82431C2.81539 6.6278 1.99997 7.53464 1.99997 8.65667L1.9989 10.5H8.46214L11.547 6.55864V4H8.51157M2.96094 5.48032H5.96094M14 9.99997C14 10.8284 13.3284 11.5 12.5 11.5C11.6716 11.5 11 10.8284 11 9.99997C11 9.17155 11.6716 8.49997 12.5 8.49997C13.3284 8.49997 14 9.17155 14 9.99997Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
