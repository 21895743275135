import { lazy } from "react";
import Home from "./Home";

const AADB2CConfirmLogin = lazy(
	() =>
		import(/* webpackChunkName: "PublicPages" */ "./Partner/AADB2CConfirmLogin")
);
const B2CSuccessRegister = lazy(
	() =>
		import(/* webpackChunkName: "PublicPages" */ "./Partner/B2CSuccessRegister")
);
const Benefits = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./Benefits")
);
const Download = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./Download")
);
const Location = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./Location")
);
const LocationRedirect = lazy(
	() =>
		import(/* webpackChunkName: "PublicPages" */ "./Location/LocationRedirect")
);
const Page404 = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./404")
);
const Partner = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./Partner")
);
const PartnerProfileCompletion = lazy(
	() =>
		import(
			/* webpackChunkName: "PublicPages" */ "./Partner/PartnerProfileCompletion"
		)
);
const PartnerSuccess = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./PartnerSuccess")
);
const SuccessRegister = lazy(
	() =>
		import(/* webpackChunkName: "PublicPages" */ "./Partner/SuccessRegister")
);
const Terms = lazy(
	() => import(/* webpackChunkName: "PublicPages" */ "./Partner/Terms")
);

export {
	AADB2CConfirmLogin,
	B2CSuccessRegister,
	Benefits,
	Download,
	Home,
	Location,
	LocationRedirect,
	Page404,
	Partner,
	PartnerProfileCompletion,
	PartnerSuccess,
	SuccessRegister,
	Terms
};
