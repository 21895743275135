import { BenefitTypeValue } from "pages/backoffice/Partners/Benefit/types";

export namespace Constants {
	export const loginStatus = [
		{ label: 401, value: "Login/Senha inválidos" },
		{ label: 502, value: "OPS! Ocorreu algum problema, tente novamente." }
	];
	export const textRedirectLogin = "Estamos redirecionando você para o login.";

	export const frontendURL = process.env.REACT_APP_FRONTEND_URL || "";
	export const appDownloadURLs = {
		iOS: process.env.REACT_APP_IOS_URL || "https://www.apple.com/br/app-store/",
		android:
			process.env.REACT_APP_ANDROID_URL || "https://play.google.com/store/apps"
	};
	export const productOffered = process.env.REACT_APP_PRODUCT_OFFERED || "";

	export const ErrorMessages = {
		fileSizeTooBig: (maxByteSize: number) =>
			`Tamanho do arquivo excede o máximo permitido (${maxByteSize / 1024}MB.)`,
		noFileExt: "Tipo de arquivo não reconhecido",
		postalCodeApiError:
			"Erro ao acessar dados do CEP. Preencha os dados manualmente.",
		requiredField: "Campo obrigatório",
		optionsLoadingError: "Erro de conexão. Por favor, recarregue as opções.",
		connectionError:
			"Erro ao carregar as informações da página. Por favor, tente novamente.",
		cityUnavailable:
			"A cidade deste CEP não está dísponível no Programa de Benefícios.",
		timeRangeError: "A abertura não pode ser posterior ao fechamento.",
		dateRangeError:
			"A data de fim deve ser igual ou posterior à data de início.",
		startDateError: "A data de início não pode ser anterior à data atual.",
		wrongFileExt: (acceptedFormats: string[]) =>
			`Arquivo deve ter um dos seguintes formatos: ${acceptedFormats.join(
				", "
			)}.`,
		minValue: (number: number) => `O valor mínimo é ${number}.`,
		greaterThan: (number: number) => `O valor deve ser maior que ${number}.`,
		maxValue: (number: number) => `O valor máximo é ${number}.`,
		mustBeInteger: "O valor deve ser um número inteiro.",
		benefitListError: "Não foi possível carregar a listagem de campanhas.",
		invalidEmail: "Email inválido.",
		badRequestForm: "Erro no preenchimento do formulário. Reveja os campos."
	} as const;

	export enum FederalUnits {
		AC = "AC",
		AL = "AL",
		AP = "AP",
		AM = "AM",
		BA = "BA",
		CE = "CE",
		DF = "DF",
		ES = "ES",
		GO = "GO",
		MA = "MA",
		MS = "MS",
		MT = "MT",
		MG = "MG",
		PA = "PA",
		PB = "PB",
		PR = "PR",
		PE = "PE",
		PI = "PI",
		RJ = "RJ",
		RN = "RN",
		RS = "RS",
		RO = "RO",
		RR = "RR",
		SC = "SC",
		SP = "SP",
		SE = "SE",
		TO = "TO"
	}

	export const FederalUnitsRecord: Record<FederalUnits, string> = {
		AC: "Acre",
		AL: "Alagoas",
		AP: "Amapá",
		AM: "Amazonas",
		BA: "Bahia",
		CE: "Ceará",
		DF: "Distrito Federal",
		ES: "Espirito Santo",
		GO: "Goiás",
		MA: "Maranhão",
		MS: "Mato Grosso do Sul",
		MT: "Mato Grosso",
		MG: "Minas Gerais",
		PA: "Pará",
		PB: "Paraíba",
		PR: "Paraná",
		PE: "Pernambuco",
		PI: "Piauí",
		RJ: "Rio de Janeiro",
		RN: "Rio Grande do Norte",
		RS: "Rio Grande do Sul",
		RO: "Rondônia",
		RR: "Roraima",
		SC: "Santa Catarina",
		SP: "São Paulo",
		SE: "Sergipe",
		TO: "Tocantins"
	} as const;

	export const DaysLabels = {
		MONDAY: "Segunda-feira",
		TUESDAY: "Terça-feira",
		WEDNESDAY: "Quarta-feira",
		THURSDAY: "Quinta-feira",
		FRIDAY: "Sexta-feira",
		SATURDAY: "Sábado",
		SUNDAY: "Domingo"
	} as const;

	export const LinkTypes: Record<string, string> = {
		contactInstagram: "Instagram",
		contactFacebook: "Facebook",
		siteUrl: "Site",
		other: "Outro"
	} as const;

	export const BenefitTypes: Record<string, string> = {
		[BenefitTypeValue.item]: "Item",
		[BenefitTypeValue.percentage]: "Porcentagem",
		[BenefitTypeValue.fixedValue]: "Valor fixo"
	} as const;

	export const ItemTypes: Record<string, string> = {
		dessert: "Sobremesa",
		extra: "Extra"
	} as const;

	export const ACCESS_TOKEN_COOKIE = "access_token";
}
