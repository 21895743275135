import colors from "colors";
import { IconProps } from "./types";

export default function IcoArchive({
	color = colors.neutral["low-pure-500"],
	size = "24",
	viewBox = "0 0 24 24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.75 11.25H14.25M4.5 7.5H19.5V19.5H4.5V7.5ZM3 4.5H21V7.5H3V4.5Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
