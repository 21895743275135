import colors from "colors";
import { IconProps } from "./types";

export default function IcoArrowDown({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 4L12 20M12 20L4 12M12 20L20 12"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
