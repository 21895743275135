import { IconProps } from "./types";

export default function IcoExternalLink({
	color = "#E98943",
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.83325 14.0831L13.4936 6.42275M5.83325 5.8335L14.0828 5.8335V14.0831"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
}
