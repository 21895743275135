import { AnimatePresence, motion } from "framer-motion";
import { QRCodeSVG } from "qrcode.react";

import { Constants } from "appConstants";
import "./styles.scss";
import { DownloadMenuProps } from "./types";

export default function DownloadMenu({
	isVisible = true,
	onClose
}: DownloadMenuProps) {
	return (
		<div className="download-menu">
			<AnimatePresence>
				{isVisible && (
					<motion.div
						initial={{ x: "100%" }}
						animate={{ x: 0 }}
						exit={{ x: "100%" }}
						transition={{ duration: 0.2, ease: "easeInOut" }}
						className="content"
					>
						<div className="flex flex-col items-center gap-8 w-1/2 text-center">
							<p>Leia o QR code com seu telefone e baixe o app CCR</p>
							<QRCodeSVG value={`${Constants.frontendURL}/download`} />
						</div>
					</motion.div>
				)}
			</AnimatePresence>
			{isVisible && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 0.5 }}
					transition={{ duration: 0.2, ease: "easeInOut" }}
					onClick={onClose}
					className="overlay"
				>
					&nbsp;
				</motion.div>
			)}
		</div>
	);
}
