export default function IcoYoutubeCircle() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2041_32361)">
				<path
					d="M16 0C24.836 0 32 7.164 32 16C32 24.836 24.836 32 16 32C7.164 32 0 24.836 0 16C0 7.164 7.164 0 16 0ZM16.392 9.33333H15.6067L14.1387 9.35067C12.1653 9.38267 9.19333 9.47333 8.18533 9.73067C7.76904 9.83209 7.38665 10.041 7.0764 10.3365C6.76614 10.632 6.53888 11.0038 6.41733 11.4147C6.176 12.2733 6.07333 13.668 6.03067 14.6947L6.00133 15.7813V16.2187L6.012 16.7867C6.04133 17.8053 6.132 19.5707 6.41733 20.5853C6.64667 21.404 7.324 22.0493 8.18533 22.2693C9.16667 22.52 12.008 22.612 13.98 22.6467L15.8533 22.6667L17.3987 22.656C19.336 22.6307 22.7227 22.5467 23.8133 22.2693C24.2299 22.1681 24.6125 21.9593 24.923 21.6638C25.2335 21.3682 25.461 20.9964 25.5827 20.5853C25.8627 19.5853 25.956 17.8547 25.9853 16.8293L25.9987 16.1253L25.9947 15.588C25.9787 14.6947 25.9067 12.568 25.5813 11.4147C25.4598 11.0038 25.2325 10.632 24.9223 10.3365C24.612 10.041 24.2296 9.83209 23.8133 9.73067C22.932 9.50533 20.5467 9.408 18.6427 9.36533L16.392 9.33333ZM13.9547 13.1867L19.1813 16L13.9547 18.8133V13.1867Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2041_32361">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
