import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Button } from "components";

import AuthTokenHelper from "helpers/AuthTokenHelper";
import useBackofficeUserToken from "hooks/useBackofficeUserToken";
import "./styles.scss";

export default function Profile({ onClose }: { onClose?: () => void }) {
	const user = useBackofficeUserToken();
	const [toggleMenuSupport, setToggleMenuSupport] = useState<boolean>(false);
	const navigate = useNavigate();

	const handleLogout = async () => {
		AuthTokenHelper.removeAuthToken();
		navigate("/");
		if (onClose) {
			onClose();
		}
	};

	return (
		<div
			className={clsx("wrapper-profile", {
				open: toggleMenuSupport
			})}
		>
			<div
				className="profile-name-wrapper"
				onClick={() => setToggleMenuSupport(!toggleMenuSupport)}
			>
				<Avatar size="lg">{user?.name.charAt(0)}</Avatar>
				<p className="font-body-semibold-extra-small">{user?.name}</p>
			</div>
			<div className="profile-options">
				<div className="divider" />
				<Button
					styled="ghost"
					onClick={handleLogout}
					disabled={!user}
					cssClass="logout"
					size="small"
				>
					Sair
				</Button>
			</div>
		</div>
	);
}
