import { AnimatePresence, motion } from "framer-motion";

import { IcoAttention } from "assets/icons";
import colors from "colors";
import Benefit from "components/Benefit";
import Button from "components/Button";
import Loading from "components/Loading";
import { TagManagerHelper } from "helpers";
import { Benefit as BenefitType } from "models/types";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { BenefitService } from "services/benefit";
import "./styles.scss";
import { SearchPageProps } from "./types";

function NoResults() {
	return (
		<div className="flex flex-col gap-8 items-center my-auto pt-40 text-center">
			<IcoAttention color={colors.neutral["low-pure-500"]} />
			<h1 className="text-4xl">Nenhum resultado encontrado</h1>
			<p className="text-lg">Tente buscar pelo nome de um estabelecimento.</p>
		</div>
	);
}

export default function SearchPage({
	isVisible,
	searchText,
	onSearch
}: SearchPageProps) {
	const [benefits, setBenefits] = useState<BenefitType[]>();
	const [loading, setLoading] = useState<boolean>(true);
	const [isLoadingVisible, setLoadingVisible] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState<number | undefined>(0);
	const [isLast, setIsLast] = useState<boolean>(false);

	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const loadingRef = useRef<HTMLDivElement>(null);
	const benefitService = new BenefitService();

	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				setLoadingVisible(entry.isIntersecting);
			});
		},
		{ threshold: [0.16, 0.84] }
	);

	useEffect(() => {
		if (loadingRef.current) {
			observer.observe(loadingRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [loadingRef, observer]);

	useEffect(() => {
		if (isLoadingVisible && searchText && !isLast) {
			setLoading(true);
			benefitService
				.searchBenefits(searchText, (currentPage || 0) + 1)
				.then((response) => {
					setIsLast(response.last);
					setCurrentPage((Number(response.pageable?.pageNumber) || 0) + 1);
					setBenefits(benefits?.concat(response.content));
				})
				.finally(() => setLoading(false));
		}
	}, [isLoadingVisible]);

	useEffect(() => {
		if (searchText) {
			setLoading(true);
			TagManagerHelper.dataLayer({ event: "search", value: searchText });
			benefitService
				.searchBenefits(searchText, 0)
				.then((response) => {
					setIsLast(response.last);
					setCurrentPage(response.pageable?.pageNumber);
					setBenefits(response.content);
				})
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
			setBenefits([]);
		}
	}, [searchText, isVisible]);

	useEffect(() => {
		if (benefits) {
			const benefitsByCategory: any = {};
			benefits?.forEach((benefit) => {
				benefit.categories.forEach((category) => {
					if (category.id in benefitsByCategory) {
						benefitsByCategory[category.id].amount += 1;
					} else {
						benefitsByCategory[category.id] = {
							name: category.name,
							amount: 0
						};
					}
				});
			});
			TagManagerHelper.dataLayer({
				event: "categorySearch",
				page: currentPage,
				benefitsByCategory
			});
		}
	}, [benefits]);

	return (
		<>
			<div className="container-search">
				<AnimatePresence>
					{isVisible && (
						<motion.div
							initial={{ y: "-100%", opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							exit={{ y: "-100%", opacity: 0 }}
							transition={{ duration: 0.3, ease: "easeOut" }}
							className="content z-10 pb-24"
						>
							<div className="wrapper-general flex-col px-[136px]">
								{searchText && (
									<h5 className="py-6 lg:py-10 text-xl font-semibold">
										Resultado de busca para {searchText}:
									</h5>
								)}
								{(!benefits || benefits?.length === 0) && !loading ? (
									<div className="justify-center">
										{searchText && searchText !== "" && <NoResults />}
									</div>
								) : (
									<>
										<div className="flex flex-wrap justify-start items-start gap-10 [&>.benefit]:max-w-[13rem] pb-20 pt-6">
											{benefits?.map((benefit, index) => {
												return (
													<Benefit
														key={`search-result-${benefit.id}-${index}`}
														data={benefit}
													/>
												);
											})}
										</div>
										{!isLast && (
											<div
												className="h-16 mb-20 justify-center"
												ref={loadingRef}
											>
												<Loading type="primary" />
											</div>
										)}
									</>
								)}
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			{isMobile && isVisible && (
				<motion.div
					initial={{ y: "100%" }}
					animate={{ y: 0 }}
					transition={{ duration: 0.2, ease: "easeInOut" }}
					className="z-[110] flex fixed bottom-0 left-0 w-screen bg-neutral-high-pure-50"
				>
					<div className="w-full flex justify-center items-center p-6 h-24 border-t border-neutral-high-100">
						<Button styled="secondary" cssClass="w-full p-4" onClick={onSearch}>
							Buscar
						</Button>
					</div>
				</motion.div>
			)}
		</>
	);
}
