import { Location } from "models/Location";
import { BaseServices } from "./baseServices";

export class LocationService extends BaseServices<Location> {
	constructor() {
		super(Location);
	}

	async listLocations() {
		return this.list(`public/cities`);
	}
}
