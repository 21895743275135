import { IconProps } from "./types";

export default function IcoFeedbackSmallCheck(
	props: Readonly<IconProps>
): JSX.Element {
	const { size = 24, viewBox = "0 0 24 25" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5Z"
				fill="#00D4A1"
				stroke="#00D4A1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 12.5L11.4 14.5L15 10.5"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
