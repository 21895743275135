import { IconProps } from "./types";

export default function IcoUnmarkedCheckbox({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
				stroke={color}
			/>
		</svg>
	);
}
