import { BrowserRouter as Router } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import { GoogleAnalytics4 } from "components";

import "App.scss";
import { Helmet } from "react-helmet-async";
import "react-toastify/dist/ReactToastify.css";
import Routers from "router";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export function App() {
	return (
		<>
			<Helmet>
				<title>Vai na Boa – Benefícios Exclusivos CCR ViaLagos</title>
				<meta
					name="description"
					content="Descubra os benefícios exclusivos do programa Vai na Boa da CCR ViaLagos. Ofertas especiais e descontos para você aproveitar na sua viagem!"
				/>
				<meta
					name="keywords"
					content="CCR ViaLagos, benefícios CCR, descontos viagem, programa de fidelidade"
				/>
			</Helmet>
			<Router>
				<GoogleAnalytics4 />
				<ToastContainer
					autoClose={5000}
					position="top-right"
					icon={false}
					closeButton={false}
					theme="dark"
					transition={Slide}
				/>
				<Routers />
			</Router>
		</>
	);
}
