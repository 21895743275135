const StringHelper = {
	getFirstName(fullName: string) {
		return fullName.split(" ")[0];
	},
	slugify(text: string) {
		return text
			.toString()
			.toLowerCase()
			.trim()
			.normalize("NFKD") // Normalizes the string to decompose characters
			.replace(/[\u0300-\u036f]/g, "") // Removes diacritical marks
			.replace(/\s+/g, "-") // Replaces remaining spaces with dashes
			.replace(/[^a-z0-9-]/g, "") // Removes any remaining non-alphanumeric characters except for dashes
			.replace(/-+/g, "-"); // Replaces multiple dashes with a single dash
	},
	isValidUUID(value: string) {
		const regexExp =
			/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
		return regexExp.test(value);
	},
	formattedPhoneNumber(phone: string) {
		if (phone.length === 10) {
			return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;
		}
		return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
	},
	formattedPhoneNumber0800(phone: string) {
		return `${phone.slice(0, 4)} ${phone.slice(4, 7)} ${phone.slice(7)}`;
	},
	isValidHttpUrl(url: string) {
		try {
			const newUrl = new URL(url);
			return newUrl.protocol === "http:" || newUrl.protocol === "https:";
		} catch (err) {
			return false;
		}
	}
};

export default StringHelper;
