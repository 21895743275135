import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type SearchState = {
	search: URLSearchParams;
};

const initialState = {
	search: new URLSearchParams()
} as SearchState;

export const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		setSearch: (state, action: PayloadAction<URLSearchParams>) => {
			(state.search as any) = action.payload;
		},
		setParam: (state, action: PayloadAction<[string, string]>) => {
			state.search.set(action.payload[0], action.payload[1]);
		},
		deleteParam: (state, action: PayloadAction<string>) => {
			state.search.delete(action.payload);
		},
		clearSearch: () => initialState
	}
});

export const { setSearch, setParam, deleteParam, clearSearch } =
	searchSlice.actions;
export default searchSlice.reducer;
