import clsx from "clsx";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Profile from "components/Profile";
import "./styles.scss";

interface HeaderProps {
	showTabs?: boolean;
	showUser?: boolean;
}

export default function Header({
	showTabs = true,
	showUser = true
}: Readonly<HeaderProps>) {
	const navigate = useNavigate();
	const [toggleAvatarMenu, setToggleAvatarMenu] = useState<boolean>(false);
	const { partnerId } = useParams();

	const tabs = [
		{
			name: "Parceiros",
			baseUrl: "/backoffice/partners"
		}
	];
	const handleToggleAvatarMenu = () => {
		if (!toggleAvatarMenu) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setToggleAvatarMenu(!toggleAvatarMenu);
	};

	return (
		<header className="header-backoffice">
			<div className="content">
				<div
					className="font-body-semibold-large"
					onClick={() => navigate("/backoffice")}
				>
					Programa de Benefícios
				</div>
				<nav className="tab-wrapper">
					{showTabs &&
						tabs.map((tab: any) => (
							<ul key={`tab-${tab.name}`}>
								<li
									key={`tab-${tab.name}`}
									role="presentation"
									onClick={() => navigate(tab.baseUrl)}
									className={clsx({
										"active-header-tab": window.location.pathname.startsWith(
											tab.baseUrl
										),
										"hidden-header-tab":
											window.location.pathname.startsWith(
												"/backoffice/partners"
											) && partnerId
									})}
								>
									<span className="font-body-regular-small">{tab.name}</span>
								</li>
							</ul>
						))}
				</nav>
				{showUser && (
					<div className="profile-wrapper">
						<div className="divider" />
						<Profile onClose={handleToggleAvatarMenu} />
					</div>
				)}
			</div>
		</header>
	);
}
