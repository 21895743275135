import { IconProps } from "./types";

export default function IcoHint({ size = "16", color = "#1D1D1D" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.5 6.5C6.5 5.67157 7.17157 5 8 5C8.82843 5 9.5 5.67157 9.5 6.5C9.5 6.97826 9.27617 7.40424 8.92755 7.67891C8.49374 8.0207 8 8.44772 8 9L8 9.5"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="8"
				cy="11"
				r="0.75"
				transform="rotate(-180 8 11)"
				fill={color}
			/>
		</svg>
	);
}
