import colors from "colors";
import { IconProps } from "./types";

export default function IcoPen({
	color = colors.neutral["low-pure-500"],
	size = "28"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 12L12.3058 3.75128C12.6916 3.3681 12.7006 2.74685 12.3259 2.35274L12.02 2.03092L11.745 1.7417C11.3583 1.33487 10.7125 1.32598 10.3147 1.72202L2 10L1 13L4 12Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
