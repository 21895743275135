import colors from "colors";
import { IconProps } from "./types";

export default function IcoArrowLeft({
	color = colors.neutral["low-pure-500"],
	size = "28"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 12L3 12M3 12L12 3M3 12L12 21"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
