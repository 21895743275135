import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { HeaderBackoffice } from "components";
import useBackofficeUserToken from "hooks/useBackofficeUserToken";
import "./styles.scss";

export default function BackofficeLayout() {
	const navigate = useNavigate();
	const user = useBackofficeUserToken();

	const verifyLogin = () => {
		if (!user) {
			navigate("/");
		}
	};

	useEffect(verifyLogin, []);

	return (
		<>
			<HeaderBackoffice />
			<div className="outlet">
				<Outlet />
			</div>
		</>
	);
}
