const ErrorHelper = {
	getObjMessageAsArray(obj: any) {
		if (typeof obj === "string") {
			return [obj];
		}
		return Object.keys(obj)
			.map((key) => {
				if (key === "code") return "";
				let message = obj[key];
				if (typeof message === "object") {
					message =
						message instanceof Array
							? message
									.map((msgItem) => this.getObjMessageAsArray(msgItem))
									.join(", ")
							: ErrorHelper.getObjMessageAsArray(message);
				}
				return message;
			})
			.join("\n");
	},
	getErrorMessage(error: any) {
		const { response } = error;
		const defaultError = "OPS! Alguma coisa deu errado. Tente novamente.";

		try {
			if (response?.status === 0 || error?.code === "ERR_NETWORK") {
				return "Ocorreu um erro de conexão. Tente novamente.";
			}
			if (response?.status === 502) {
				return "Falha na conexão, tente novamente em alguns instantes";
			}
			if (response?.data) {
				if (typeof response.data === "string" && response.data !== "None") {
					return response.data;
				}

				if (typeof response.data === "object") {
					const dataToParse = response.data?.errors || response.data;
					const parsed =
						dataToParse instanceof Array
							? dataToParse
							: ErrorHelper.getObjMessageAsArray(dataToParse);
					return parsed;
				}
			}
			return defaultError;
		} catch {
			return defaultError;
		}
	},
	getResponseErrorMessage(error: any) {
		const { response } = error;
		if (response?.status === 0) {
			return "Ocorreu um erro de conexão. Tente novamente.";
		}
		if (response?.status === 502) {
			return "Falha na conexão, tente novamente em alguns instantes";
		}
		if (response?.data) {
			if (typeof response.data === "string" && response.data !== "None") {
				return response.data;
			}

			if (typeof response.data === "object") {
				if (typeof response.data.message === "string") {
					return response.data.message;
				}

				const dataToParse = response.data?.detail || response.data;
				const parsed =
					dataToParse instanceof Array
						? dataToParse
						: ErrorHelper.getObjMessageAsArray(dataToParse);
				return Array.isArray(parsed)
					? parsed.join("\n").replace("non_field_errors: ", "")
					: parsed;
			}
		}
		return "OPS! Alguma coisa deu errado. Tente novamente.";
	}
};

export default ErrorHelper;
