import { IconProps } from "./types";

export default function IcoCloseModal({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.9998 4L11.7698 12.2265M11.7698 12.2265L4.45166 19.5413M11.7698 12.2265L19.5467 20M11.7698 12.2265L3.99854 4.45865"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
