import { IconProps } from "./types";

export default function IcoAttachment(props: Readonly<IconProps>): JSX.Element {
	const { size = 24, color = "#1D1D1D" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 17.6429V8.5C8 6.29086 9.79086 4.5 12 4.5C14.2091 4.5 16 6.29086 16 8.5V17.7381C16 19.2635 14.7635 20.5 13.2381 20.5C11.7128 20.5 10.4762 19.2635 10.4762 17.7381V9.51882C10.4762 8.67725 11.1584 7.99502 12 7.99502C12.8416 7.99502 13.5238 8.67725 13.5238 9.51882V17.6429"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
