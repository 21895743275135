import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { baseConfig } from "config";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { logOutUserIfTokenInvalid } from "./token";

const api = axios.create({
	baseURL: baseConfig.baseURL,
	xsrfCookieName: "csrftoken",
	xsrfHeaderName: "X-CSRFToken",
	paramsSerializer: {
		indexes: null
	}
});

api.interceptors.request.use(
	(config: any) => {
		const newConfig = {
			...config,
			headers: {
				...config.headers
			}
		};
		const token = AuthTokenHelper.getAuthToken();
		if (token) {
			newConfig.headers.Authorization = `Bearer ${token}`;
		}
		return newConfig;
	},
	(error: any) => Promise.reject(error)
);

api.interceptors.response.use(
	(response) => response,
	(error) => logOutUserIfTokenInvalid(error)
);

const get = async (url: string, config?: any) => {
	const response: AxiosResponse = await api.get(url, {
		...config
	});
	return response.data;
};

const patch = async (url: string, data: any, config?: any) => {
	const response: AxiosResponse = await api.patch(url, data, {
		...config
	});
	return response.data;
};

const post = async (url: string, data: any, config?: any) => {
	const response: AxiosResponse = await api.post(url, data, {
		...config
	});
	return response.data;
};

const remove = async (url: string, config?: any) => {
	const response: AxiosResponse = await api.delete(url, {
		...config
	});
	return response.data;
};

const put = async (url: string, data: any, config?: any) => {
	const response: AxiosResponse = await api.put(url, data, {
		...config
	});
	return response.data;
};

const postWithResponse = async <T = any,>(
	url: string,
	data: any,
	config?: AxiosRequestConfig
) => {
	const response: AxiosResponse<T> = await api.post(url, data, config || {});
	return response;
};

export { get, patch, post, postWithResponse, put, remove };
