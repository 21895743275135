export default function IcoLinkedInCircle() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2041_32358)">
				<path
					d="M16 0C24.836 0 32 7.164 32 16C32 24.836 24.836 32 16 32C7.164 32 0 24.836 0 16C0 7.164 7.164 0 16 0ZM11.9347 12.5387H8.22V23.3333H11.9333V12.5387H11.9347ZM21.056 12.2827C19.2907 12.2827 18.3987 13.1213 17.876 13.824L17.7067 14.0653V12.5373H13.992L14.0053 13.1827C14.0133 14.0307 14.0147 15.5133 14.012 17.0893L13.992 23.3333H17.7067V17.304C17.7067 16.9813 17.7307 16.6587 17.8293 16.428C18.0987 15.784 18.7093 15.116 19.736 15.116C21.0093 15.116 21.5587 16.004 21.6133 17.332L21.6173 17.556V23.3333H25.3333V17.1427C25.3333 13.8267 23.5 12.2827 21.056 12.2827ZM10.1013 7.33333C8.83067 7.33333 8 8.14 8 9.19867C8 10.172 8.71467 10.9653 9.83733 11.0547L10.0773 11.0627C11.372 11.0627 12.18 10.2347 12.18 9.19867C12.1547 8.14 11.3733 7.33333 10.1013 7.33333Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2041_32358">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
