import colors from "colors";
import { IconProps } from "./types";

export default function IcoRestaurant({
	color = colors.neutral["low-pure-500"],
	size = "16"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M5.875 2C4.83947 2 4 3.10612 4 4.47059C4 5.48369 4.46279 7.11876 5.125 7.5V13.25C5.125 13.6642 5.46079 14 5.875 14C6.28921 14 6.625 13.6642 6.625 13.25V7.5C7.28721 7.11876 7.75 5.48369 7.75 4.47059C7.75 3.10612 6.91053 2 5.875 2Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 13.25V2C10.7574 2 9.75 3.00736 9.75 4.25V8.75C9.75 9.16421 10.0858 9.5 10.5 9.5V13.25C10.5 13.6642 10.8358 14 11.25 14C11.6642 14 12 13.6642 12 13.25Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
