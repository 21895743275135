import { SectionProps } from "./types";

import "./styles.scss";

export default function Section({
	title,
	children,
	headerClassName,
	containerClassName,
	contentClassName,
	headerChildren
}: SectionProps) {
	return (
		<div className={`section-container ${containerClassName}`}>
			<div className={`section-header ${headerClassName}`}>
				<span>{title}</span>
				{headerChildren}
			</div>
			<div className={`section-content ${contentClassName}`}>{children}</div>
		</div>
	);
}
