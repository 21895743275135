import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { baseConfig } from "config";
import AuthHelper from "helpers/AuthHelper";
import { User } from "models/auth/User";
import { BaseServices } from "./baseServices";

export class AuthService extends BaseServices<User> {
	constructor() {
		super(User);
	}

	ACCESS_TOKEN = "access_token";

	async login() {
		const redirect_uri = `${window.location.protocol}//${window.location.host}/backoffice/`;
		const loginUrl = `${baseConfig.baseURL}/oauth2/authorize/azure?redirect_uri=${redirect_uri}`;
		window.location.assign(loginUrl);
	}

	async logout() {
		return this.get("auth/logout/");
	}

	async refreshLogin(dispatch: Dispatch<AnyAction>) {
		const response = this.get("auth/token/refresh/")
			.then(() => AuthHelper.startTokenRefreshTimeout(dispatch))
			.catch((error) => {
				if (error.code === "ERR_NETWORK") {
					AuthHelper.startTokenRefreshTimeout(dispatch);
				}
			});
		return response;
	}
}
