import { IcoMapMarker } from "assets/icons";
import defaultBenefit from "assets/images/default-benefit.png";
import colors from "colors";
import { Benefit as BenefitType } from "models/types";
import "./styles.scss";

export default function Benefit({
	data: { name, image, categories, description, location },
	mobileVersion
}: {
	data: BenefitType;
	mobileVersion?: boolean;
}) {
	const safeImage = image || defaultBenefit;

	if (mobileVersion)
		return (
			<div className="benefit mobile">
				<div className="benefit-image mobile">
					<img loading="lazy" src={safeImage} alt={name} />
				</div>
				<div className="benefit-content-mobile">
					<h5 className="font-body-semibold-medium">{name}</h5>
					<p className="font-body-regular-tiny tracking-wider">
						{categories
							.map((category) => category.name)
							.join(", ")
							.toUpperCase()}{" "}
						• {location.name.toUpperCase()}
					</p>
					<p className="benefit-description">{description}</p>
				</div>
			</div>
		);

	return (
		<div className="benefit">
			<div className="benefit-image">
				<img loading="lazy" src={safeImage} alt={name} />
			</div>
			<p className="tag">
				{categories.map((category) => category.name).join(", ")}
			</p>
			<div className="location">
				<IcoMapMarker color={colors.neutral["low-pure-500"]} />
				<p>{location.name}</p>
			</div>
			<h5 className="text-xl font-semibold text-neutral-low-pure-500">
				{name}
			</h5>
			<p className="text-base">{description}</p>
		</div>
	);
}
