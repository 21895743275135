import { useEffect } from "react";

export default function GoogleAnalytics4() {
	const id = process.env.REACT_APP_GA4_ID;
	const l = "dataLayer";
	const dl = l !== "dataLayer" ? `&l=${l}` : "";
	const source = `https://www.googletagmanager.com/gtm.js?id=${id}${dl}`;

	if (!id) return <noscript />;

	const googleAnalytics4Script = `
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != "dataLayer" ? "&l=" + l : "";
			j.defer = true;
			j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', '${l}', '${id}');
	`;

	useEffect(() => {
		const script = document.createElement("script");

		script.text = googleAnalytics4Script;
		script.defer = true;

		const scriptIsLoaded = document.querySelector(`script[src='${source}']`);
		if (!scriptIsLoaded) document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, []);

	return (
		<noscript>
			<iframe
				title="google-tag-manager"
				loading="lazy"
				src={`https://www.googletagmanager.com/ns.html?id=${id}`}
				height="0"
				width="0"
				style={{ display: "none", visibility: "hidden" }}
			/>
		</noscript>
	);
}
