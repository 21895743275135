import { IconProps } from "./types";

export default function IcoWindows({ size = "32" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 11.5958C4 9.22011 4 6.84444 4 4.46877C4.08587 4.29704 4.20036 4.18255 4.37209 4.09668C6.74776 4.09668 9.12343 4.09668 11.4991 4.09668C11.4991 6.58684 11.4991 9.077 11.4991 11.5958C8.98032 11.5958 6.49016 11.5958 4 11.5958Z"
				fill="#1D1D1D"
			/>
			<path
				d="M12.501 4.09668C14.8766 4.09668 17.2523 4.09668 19.628 4.09668C19.9142 4.21117 20.0001 4.44015 20.0001 4.755C20.0001 6.95893 20.0001 9.19149 20.0001 11.3954C20.0001 11.4527 20.0001 11.5099 20.0001 11.5672C17.4813 11.5672 14.9911 11.5672 12.501 11.5672C12.501 9.077 12.501 6.58684 12.501 4.09668Z"
				fill="#1D1D1D"
			/>
			<path
				d="M4 19.7247C4 17.349 4 14.9733 4 12.5977C6.49016 12.5977 8.98032 12.5977 11.4991 12.5977C11.4991 15.1164 11.4991 17.6066 11.4991 20.0968C11.4419 20.0968 11.356 20.0968 11.2987 20.0968C9.09481 20.0968 6.86225 20.0968 4.65832 20.0968C4.34347 20.0968 4.11449 20.0109 4 19.7247Z"
				fill="#1D1D1D"
			/>
			<path
				d="M12.501 20.0968C12.501 17.578 12.501 15.0878 12.501 12.5977C14.9911 12.5977 17.4813 12.5977 20.0001 12.5977C20.0001 12.6549 20.0001 12.7121 20.0001 12.7408C20.0001 14.9733 20.0001 17.2345 20.0001 19.4671C20.0001 19.8964 19.8283 20.0681 19.399 20.0681C17.1664 20.0681 14.9053 20.0681 12.6727 20.0681C12.6155 20.0968 12.5582 20.0968 12.501 20.0968Z"
				fill="#1D1D1D"
			/>
		</svg>
	);
}
