import colors from "colors";
import { IconProps } from "./types";

function IcoReload({
	color = colors.neutral["low-pure-500"],
	size = "28"
}: IconProps): JSX.Element {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 -5 14 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.666687 1.66675V5.66675M0.666687 5.66675H4.66669M0.666687 5.66675L3.76002 2.76013C4.68063 1.84108 5.87499 1.24611 7.16314 1.06487C8.45129 0.883637 9.76345 1.12595 10.9019 1.7553C12.0404 2.38466 12.9435 3.36696 13.4751 4.5542C14.0067 5.74143 14.1381 7.06929 13.8495 8.3377C13.5609 9.60611 12.8678 10.7464 11.8748 11.5866C10.8817 12.4269 9.64252 12.9217 8.34383 12.9964C7.04514 13.0712 5.75734 12.7218 4.67446 12.001C3.59158 11.2802 2.77228 10.227 2.34002 9.00013"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default IcoReload;
