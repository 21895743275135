import { IconProps } from "./types";

export default function IcoFeedbackCheck({
	color = "#1D1D1D",
	size = "72"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 72 72"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="36"
				cy="36"
				r="28"
				transform="rotate(-180 36 36)"
				stroke={color}
				strokeWidth="4"
			/>
			<path
				d="M22 36L32.7333 46L50 26"
				stroke={color}
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
