import { IconProps } from "./types";

export default function IcoClose({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="12"
				cy="12"
				r="8"
				transform="rotate(-180 12 12)"
				stroke={color}
			/>
			<path d="M15 9L9.1701 14.8299" stroke="#1D1D1D" strokeLinecap="round" />
			<path
				d="M14.8359 15L9.00603 9.1701"
				stroke={color}
				strokeLinecap="round"
			/>
		</svg>
	);
}
