export enum BenefitTypeValue {
	item = "ITEM",
	percentage = "PERCENTAGE",
	fixedValue = "FIXED_VALUE"
}

export interface BenefitTypeProps {
	type?: BenefitTypeValue | undefined;
	detail?: string;
}

export interface BenefitInterface {
	id: string;
	active: boolean;
	archived: boolean;
	archivedAt: string;
	archivingDate?: string;
	name: string;
	description: string;
	benefitType: BenefitTypeProps;
	startDate?: string;
	endDate?: string;
	couponLimitAmount?: number;
	couponsAlreadyCreated: number;
	status: string;
}

export enum BenefitTypeFields {
	type = "type",
	detail = "detail"
}

export enum BenefitFields {
	active = "active",
	name = "name",
	description = "description",
	benefitType = "benefitType",
	startDate = "startDate",
	endDate = "endDate",
	couponLimitAmount = "couponLimitAmount",
	status = "status"
}
