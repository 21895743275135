import { BaseModel } from "./BaseModel";
import { LocationProps } from "./types";

export class Location extends BaseModel {
	id: string;
	name: string;
	description: string;
	image: string;
	state: string;

	constructor(data: LocationProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.image = data.image;
		this.state = data.state;
		this.description = data.description;
	}
}
