import clsx from "clsx";
import Label from "components/Label";
import { InputHTMLAttributes } from "react";
import "./styles.scss";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	label?: string;
	error?: string | Array<string>;
	className?: string;
	touched?: boolean;
	isOptional?: boolean;
};

export default function Input({
	label,
	placeholder,
	type,
	name,
	value,
	disabled,
	error,
	touched,
	onChange,
	className = "",
	isOptional,
	...args
}: InputProps) {
	return (
		<div
			className={clsx({ "disabled-text": disabled }, `container ${className}`)}
		>
			<div className="flex gap-1">
				{label && <Label isRequired={false}>{label}</Label>}
				{isOptional && <p className="text-sm">(opcional)</p>}
			</div>
			<input
				type={type}
				name={name}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				disabled={disabled}
				className={clsx(
					{ error: error && touched, "disabled-text": disabled },
					`input-component ${className}`
				)}
				required={!isOptional}
				{...args}
			/>
			{error && touched && (
				<div className="error-text">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
