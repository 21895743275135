const colors = {
	neutral: {
		"low-300": "#999999",
		"low-400": "#666666",
		"low-pure-500": "#1D1D1D",
		"high-pure-50": "#FFFFFF",
		"high-100": "#F0F0F0",
		"high-200": "#DBDBDB"
	},
	"feedback-positive": {
		"low-950": "#1A2F2A",
		"pure-500": "#00D4A1",
		"high-50": "#E5FBF6"
	},
	"feedback-alert": {
		"low-950": "#322E21",
		"pure-500": "#F6C643",
		"high-50": "#FFFAEA"
	},
	"feedback-negative": {
		"low-950": "#331F21",
		"pure-500": "#DE557C",
		"high-50": "#FCEEF2"
	},
	"feedback-focus": {
		"low-950": "#272732",
		"pure-500": "#8887F3",
		"high-50": "#F3F3FE"
	},
	"branding-highway": {
		"low-950": "#231D2E",
		"low-900": "#2A1D3F",
		"low-800": "#371C62",
		"low-700": "#441C85",
		"low-600": "#511CA7",
		"pure-500": "#5E1CCA",
		"high-400": "#7E49D5",
		"high-300": "#9E77DF",
		"high-200": "#BFA4EA",
		"high-100": "#DFD2F4",
		"high-50": "#EFE8FA"
	},
	transparent: "transparent"
};

module.exports = colors;
