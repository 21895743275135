import colors from "colors";
import { ComplexIconProps } from "./types";

export default function IcoInfo({
	contentColor = colors.neutral["high-pure-50"],
	borderColor = colors.neutral["low-pure-500"],
	fillColor = colors.neutral["low-pure-500"],
	size = "24"
}: ComplexIconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 72 72"
			fill={fillColor}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M64 36C64 51.464 51.464 64 36 64C20.536 64 8 51.464 8 36C8 20.536 20.536 8 36 8C51.464 8 64 20.536 64 36Z"
				stroke={borderColor}
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M36.0015 32.5013L36.0015 50.0013"
				stroke={contentColor}
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle cx="35.9985" cy="23.9987" r="3" fill={contentColor} />
		</svg>
	);
}
