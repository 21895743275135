import { Constants } from "appConstants";
import { baseConfig } from "config";
import CookieHelper from "./CookieHelper";
import LocalStorageHelper from "./LocalStorageHelper";

const AuthTokenHelper = {
	setAuthToken(value: string) {
		CookieHelper.setCookies(Constants.ACCESS_TOKEN_COOKIE, value);
		LocalStorageHelper.setInternalToken(value);
	},

	getAuthToken() {
		const cookieToken = CookieHelper.getCookies(Constants.ACCESS_TOKEN_COOKIE);
		if (cookieToken) return cookieToken;
		const localStorageToken = LocalStorageHelper.getInternalToken();
		return localStorageToken;
	},

	removeAuthToken() {
		LocalStorageHelper.removeInternalToken();
		CookieHelper.removeCookie(Constants.ACCESS_TOKEN_COOKIE, {
			domain: baseConfig.baseURLDomain
		});
	},

	setCookie(value: string | undefined, cookieName: string) {
		CookieHelper.setCookies(cookieName, value, 1);
	},

	getCookie(cookieName: string) {
		return CookieHelper.getCookies(cookieName);
	},

	removeCookie(cookieName: string) {
		CookieHelper.removeCookie(cookieName);
	}
};

export default AuthTokenHelper;
