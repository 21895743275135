import { IconProps } from "./types";

export default function IcoGantt({
	color = "#1D1D1D",
	size = "32"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.33301 4.7583V28.7583H28.333M10.333 4.7583H22.333V7.7583H10.333V4.7583ZM14.833 12.7583H26.833V15.7583H14.833V12.7583ZM20.833 20.7583H28.333V23.7583H20.833V20.7583Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
