import { IconProps } from "./types";

export default function IcoAttention({
	color = "#5E1CCA",
	size = "72"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 72 72"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="36"
				cy="36"
				r="28"
				transform="rotate(-180 36 36)"
				stroke={color}
				strokeWidth="4"
			/>
			<path
				d="M36 39.5L36 22"
				stroke={color}
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="36"
				cy="48"
				r="3"
				transform="rotate(-180 36 48)"
				fill={color}
			/>
		</svg>
	);
}
