import colors from "colors";
import { ComplexIconProps } from "./types";

export default function IcoAlert({
	fillColor = colors["feedback-alert"]["pure-500"],
	borderColor = colors["feedback-alert"]["pure-500"],
	viewBox = "0 0 72 72",
	contentColor = colors.neutral["high-pure-50"],
	size = "24"
}: ComplexIconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={viewBox}
			fill={fillColor}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 36C8 20.536 20.536 8 36 8C51.464 8 64 20.536 64 36C64 51.464 51.464 64 36 64C20.536 64 8 51.464 8 36Z"
				stroke={borderColor}
				strokeWidth="4"
				strokeLinejoin="round"
			/>
			<path
				d="M36 39.5L36 22"
				stroke={contentColor}
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="36"
				cy="48"
				r="3"
				transform="rotate(-180 36 48)"
				fill={contentColor}
			/>
		</svg>
	);
}
