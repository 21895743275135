import colors from "colors";
import { IconProps } from "./types";

export default function IcoReverter({
	color = colors.neutral["low-pure-500"],
	size = "12"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.7678 7.28132C10.9128 7.4292 10.9104 7.66662 10.7625 7.81162L8.51254 10.0178C8.36672 10.1608 8.13328 10.1608 7.98746 10.0178L5.73746 7.81162C5.58958 7.66662 5.58724 7.4292 5.73224 7.28132C5.87724 7.13344 6.11466 7.1311 6.26254 7.2761L7.875 8.85714L7.875 3.13153C7.875 2.92442 8.04289 2.75653 8.25 2.75653C8.45711 2.75653 8.625 2.92442 8.625 3.13153L8.625 8.85714L10.2375 7.2761C10.3853 7.1311 10.6228 7.13344 10.7678 7.28132Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.26776 4.71874C6.41276 4.57086 6.41042 4.33344 6.26254 4.18844L4.01254 1.98227C3.86672 1.83929 3.63328 1.83929 3.48746 1.98227L1.23746 4.18844C1.08958 4.33344 1.08724 4.57086 1.23224 4.71874C1.37724 4.86662 1.61466 4.86896 1.76254 4.72396L3.375 3.14292L3.375 8.86853C3.375 9.07564 3.54289 9.24353 3.75 9.24353C3.95711 9.24353 4.125 9.07564 4.125 8.86853L4.125 3.14292L5.73746 4.72396C5.88534 4.86896 6.12276 4.86662 6.26776 4.71874Z"
				fill={color}
			/>
		</svg>
	);
}
