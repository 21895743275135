import { ObjectWithAnyValues } from "utils/types";
import { BaseModel } from "../BaseModel";
import { UserProps } from "./types";

export class User extends BaseModel {
	name: string;
	email: string;
	authorities: [];

	constructor(data: UserProps) {
		super(data);
		this.name = data.name;
		this.email = data.email;
		this.authorities = data.authorities;
	}

	static createFromResponse(response: ObjectWithAnyValues, Model: any = this) {
		if (!response) {
			return response;
		}

		return new Model({ ...response });
	}
}
