export const faq = {
	title: "Perguntas frequentes",
	data: [
		{
			title:
				"Como baixar o aplicativo para ter acesso ao Programa de Benefícios Vai na Boa?",
			content: `
				Baixe o aplicativo CCR Rodovias que está disponível para download na App Store para dispositivos com iOS e na Play Store para dispositivos com Android.
				`
		},
		{
			title:
				"Como faço para me cadastrar no Programa de Benefícios Vai na Boa?",
			content: `
				<p>Baixe o aplicativo CCR Rodovias e realize o cadastro, assim você está automaticamente participando do programa. Certifique-se de preencher todos os campos obrigatórios para garantir uma experiência completa.</p>
				`
		},
		{
			title: "Como escolho um estabelecimento credenciado?",
			content: `
				<p>No aplicativo CCR Rodovias, selecione a CCR ViaLagos e vá até a aba de benefícios. Explore os estabelecimentos disponíveis por categoria ou proximidade para encontrar as melhores opções.</p>
				`
		},
		{
			title: "Como ativo um cupom digital de desconto?",
			content: `
				<p>O cupom digital é resgatado e ativado apenas no aplicativo CCR Rodovias. Após selecionar o estabelecimento desejado, ative o cupom digital que ficará valido durante 2 horas. Agora é só apresentar no estabelecimento.</p>
				`
		},
		{
			title: "Como me tornar um parceiro do Programa de Benefícios Vai na Boa?",
			content: `
				<p>Preencha o formulário na <u style="color: black;"><a href="/parceiro">página de parceiros</a></u> e aguarde entrarmos em contato.</p>
				`
		}
	]
};
