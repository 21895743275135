import Button from "components/Button";
import { useEffect, useState } from "react";
import "./styles.scss";
import { FixedButtonProps } from "./types";

export default function FixedButton({
	children,
	activeAreaRef,
	onClick,
	...props
}: FixedButtonProps) {
	const [isVisible, setIsVisible] = useState(false);

	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				setIsVisible(entry.isIntersecting);
			});
		},
		{ threshold: [0.16, 0.84] }
	);

	useEffect(() => {
		if (activeAreaRef.current) {
			observer.observe(activeAreaRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [activeAreaRef, observer]);

	return (
		<div {...props} className={`fixed-button ${isVisible ? "is-visible" : ""}`}>
			<Button styled="primary" size="medium" onClick={onClick}>
				{children}
			</Button>
		</div>
	);
}
