import { IconProps } from "./types";

export default function IcoDevice({
	color = "#1D1D1D",
	size = "32"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.43628 19.8208V7.7583C5.43628 6.65373 6.33171 5.7583 7.43628 5.7583H23.5427C24.6472 5.7583 25.5427 6.65373 25.5427 7.7583V8.0083M14.7234 22.6333H6C4.89543 22.6333 4 21.7379 4 20.6333V19.8208H14.7234M20 27.7583H26C27.1046 27.7583 28 26.8629 28 25.7583V12.7583C28 11.6537 27.1046 10.7583 26 10.7583H20C18.8954 10.7583 18 11.6537 18 12.7583V25.7583C18 26.8629 18.8954 27.7583 20 27.7583Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle cx="22.8809" cy="25.3833" r="0.625" fill={color} />
		</svg>
	);
}
