import { ContainerProps } from "./types";

import "./styles.scss";

export default function Container({ children, ...props }: ContainerProps) {
	const { className } = props;

	return (
		<div {...props} className={`container-summary ${className || ""}`}>
			{children}
		</div>
	);
}
