import "./styles.scss";

type LabelProps = {
	children: React.ReactNode;
	isRequired: boolean;
	className?: string;
};
export default function Label({ children, isRequired, className }: LabelProps) {
	return (
		<div className={`label ${className}`}>
			{children}
			{isRequired && <span>*</span>}
		</div>
	);
}
