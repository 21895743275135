import colors from "colors";
import { IconProps } from "./types";

export default function IcoError({
	color = colors.neutral["high-pure-50"],
	viewBox = "0 0 24 25",
	size = "25"
}: Readonly<IconProps>) {
	return (
		<svg
			width={size}
			height={size}
			id="eyg6kQqaM0N1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={viewBox}
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<path
				d="M3,12.5c0-4.97056,4.02944-9,9-9s9,4.02944,9,9-4.0294,9-9,9-9-4.0294-9-9Z"
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.1,15.5l5.4-6"
				transform="translate(-1.860284 0.066983)"
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.1,15.5l2.634049-2.92672L16.5,9.5"
				transform="matrix(-.024496 0.9997-.9997-.024496 24.75001-.989753)"
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
