import colors from "colors";
import { IconProps } from "./types";

export default function IcoBuilding({
	color = colors.neutral["low-pure-500"],
	size = "16"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M2 14H3.875M3.875 14V2H12.125V14M3.875 14H12.125M12.125 14H14M9.5 14V10H7.5"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
