export default function IcoFeedbackError() {
	// TODO: permitir resize
	return (
		<svg
			width="40"
			height="41"
			viewBox="0 0 40 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.0966797" width="40" height="40" rx="20" fill="#F83446" />
			<path
				d="M28.0003 12.0967L19.7702 20.3231M19.7702 20.3231L12.4521 27.638M19.7702 20.3231L27.5472 28.0967M19.7702 20.3231L11.999 12.5553"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
