import { IconProps } from "./types";

export default function IcoRefresh(props: Readonly<IconProps>): JSX.Element {
	const { color = "#1D1D1D", size = "24" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.3333 11.1667L20 13.8333M17.3333 11.1667L14.6667 13.8333M17.3333 11.1667L17.3333 17.8333C17.3333 19.3061 16.1394 20.5 14.6667 20.5L9.33333 20.5C7.86057 20.5 6.66667 19.3061 6.66667 17.8333M4 11.1667L6.66667 13.8333M6.66667 13.8333L9.33333 11.1667M6.66667 13.8333L6.66667 7.16667C6.66667 5.69391 7.86057 4.5 9.33333 4.5L14.6667 4.5C16.1394 4.5 17.3333 5.69391 17.3333 7.16667"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
