import { IconProps } from "./types";

export default function IcoCloseError(props: Readonly<IconProps>): JSX.Element {
	const { color = "#F83446", size = "24" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5Z"
				fill={color}
				stroke={color}
				strokeLinecap="round"
			/>
			<path
				d="M15 9.5L9.1701 15.3299M14.8359 15.5L9.00603 9.6701"
				stroke="white"
				strokeLinecap="round"
			/>
		</svg>
	);
}
