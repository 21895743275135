import {
	IcoExternalLink,
	IcoLinkedInCircle,
	IcoYoutubeCircle
} from "assets/icons";
import logo from "assets/images/ccr-logo-25.svg";
import vaiNaBoaLogo from "assets/images/vainaboa-logo.svg";

import { StringHelper } from "helpers";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import { formattedPhoneNumber0800 } from "utils";
import { strings } from "./strings";

import "./styles.scss";

export default function Footer() {
	const { locations } = useSelector((state: RootState) => state.location);
	const sortedLocations = [...locations].sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	return (
		<div className="container-footer text-neutral-high-pure-50">
			<div className="content-list-phone">
				<div className="wrapper-general">
					<div className="contact gap-1">
						<a href={`tel:${strings.phone}`} target="_blank" rel="noreferrer">
							&nbsp;
						</a>
						<div className="footer-section-title">Disque CCR ViaLagos</div>
						<div className="footer-section-text">
							{formattedPhoneNumber0800(strings.phone)}
						</div>
					</div>
					<div className="contact gap-1">
						<a href={`tel:${strings.phone2}`} target="_blank" rel="noreferrer">
							&nbsp;
						</a>
						<div className="footer-section-title">
							Para deficientes auditivos
						</div>
						<div className="footer-section-text">
							{formattedPhoneNumber0800(strings.phone2)}
						</div>
					</div>
					<div className="contact gap-1">
						<a href={`tel:${strings.phone2}`} target="_blank" rel="noreferrer">
							&nbsp;
						</a>
						<div className="footer-section-title">WhatsApp</div>
						<div className="footer-section-text">
							{formattedPhoneNumber0800(strings.phone3)}
						</div>
					</div>
				</div>
			</div>
			<div className="content-list-links">
				<div className="wrapper-general">
					<div className="links lg:pt-12 pt-0 pb-4 items-baseline">
						<img src={vaiNaBoaLogo} alt="logo vai na boa" className="h-7" />
					</div>
					<div className="links">
						<div className="footer-section-title-big mb-4">Costa do Sol</div>
						{sortedLocations?.map((location) => (
							<Link
								to={`localidade/${StringHelper.slugify(location.name)}`}
								key={`location-${location.name}`}
								className="font-body-bold-extra-small mb-4"
							>
								{location.name}
							</Link>
						))}
					</div>
					<div className="links py-0 lg:py-6">
						<div className="footer-section-title-big mb-4">Institucional</div>
						<a
							href="https://www.rodoviadoslagos.com.br/"
							target="_blank"
							rel="noreferrer"
							className="font-body-bold-extra-small"
						>
							CCR ViaLagos <IcoExternalLink size="16" color="#fff" />
						</a>
						<a
							href="https://www.grupoccr.com.br/"
							target="_blank"
							rel="noreferrer"
							className="font-body-bold-extra-small"
						>
							Grupo CCR <IcoExternalLink size="16" color="#fff" />
						</a>
					</div>
				</div>
			</div>
			<div className="content-about">
				<div className="wrapper-general p-6 lg:py-10 lg:px-[120px]">
					<div className="links-shared gap-8">
						<div className="lg:flex">
							<div className="logo">
								<img src={logo} alt="Logo CCR 25 anos" />
							</div>
							<div className="address-link">
								<address className="font-body-regular-small not-italic">
									Grupo CCR <br />
									Av. Chedid Jafet, 222 - Bloco B - 4º andar - Sala 08 - Vila
									Olímpia, São Paulo - SP, 04551-065
								</address>
							</div>
						</div>
						<div className="shared">
							<a
								href="https://www.linkedin.com/company/grupoccroficial/"
								target="_blank"
								rel="noreferrer"
							>
								<IcoLinkedInCircle />
							</a>
							<a
								href="https://www.youtube.com/user/GrupoCCROficial"
								target="_blank"
								rel="noreferrer"
							>
								<IcoYoutubeCircle />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
