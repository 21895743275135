import Cookies from "js-cookie";

const CookieHelper = {
	setCookies(name: string, value: any, expire = 0) {
		const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
		const expireInMillis = expire * ONE_DAY_IN_MILLIS;
		const expirationDate = new Date();

		expirationDate.setTime(expirationDate.getTime() + expireInMillis);
		Cookies.set(name, value, { expires: expirationDate });
	},

	getCookies(name: string) {
		return Cookies.get(name);
	},

	removeCookie(name: string, options?: Record<string, string>) {
		Cookies.remove(name, options);
	}
};

export default CookieHelper;
