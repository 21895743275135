import { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Header from "components/HeaderBackoffice";
import Loading from "components/Loading";
import { baseConfig } from "config";
import { DefaultLayout } from "layouts";
import {
	AADB2CConfirmLogin,
	B2CSuccessRegister,
	Benefits,
	Download,
	Home,
	LocationRedirect,
	Page404,
	Partner,
	PartnerProfileCompletion,
	PartnerSuccess,
	SuccessRegister,
	Terms
} from "pages";

const BackofficeRoutes = lazy(
	() => import(/* webpackChunkName: "BackofficePages" */ "./backoffice")
);

function Routers() {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}, 100);
	}, [pathname]);

	const home = <Home />;

	return (
		<Suspense
			fallback={
				<div className="p-12 flex flex-row justify-center">
					<Loading type="primary" />
				</div>
			}
		>
			<Routes>
				<Route
					path="/"
					element={
						baseConfig.landingPageEnabled ? (
							<DefaultLayout />
						) : (
							<Navigate to="/backoffice" />
						)
					}
				>
					<Route path="/" element={home} />
					<Route path="/localidade/:cityCode" element={<LocationRedirect />} />
					<Route path="/parceiro" element={<Partner />} />
					<Route path="/parceiro/sucesso" element={<PartnerSuccess />} />
					<Route path="/download" element={<Download />} />
					<Route path="/beneficios" element={<Benefits />} />
					<Route path="/*" element={<Page404 />} />
				</Route>

				<Route path="/confirm-login" element={<AADB2CConfirmLogin />} />
				<Route path="/backoffice/*" element={<BackofficeRoutes />} />
				<Route path="/parceiro/termo/:partnerRegisterId" element={<Terms />} />
				<Route
					path="/parceiro/b2c/sucesso/:partnerRegisterId"
					element={<B2CSuccessRegister />}
				/>
				<Route
					path="/parceiro/registrar/:partnerRegisterId"
					element={<PartnerProfileCompletion />}
				/>
				<Route
					path="/parceiro/registrar/sucesso"
					element={<SuccessRegister />}
				/>
				<Route
					path="/parceiro/criar/:prospectId"
					element={
						<>
							<Header showTabs={false} showUser={false} />
							<PartnerProfileCompletion />
						</>
					}
				/>
				<Route path="/*" element={<Page404 />} />
			</Routes>
		</Suspense>
	);
}

export default Routers;
