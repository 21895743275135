import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Location } from "models/types";

const initialState = {
	locations: []
} as { locations: Location[] };

export const locationSlice = createSlice({
	name: "locations",
	initialState,
	reducers: {
		setLocations: (state, action: PayloadAction<any>) => {
			state.locations = action.payload;
		}
	}
});

export const { setLocations } = locationSlice.actions;
export default locationSlice.reducer;
