import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Constants } from "appConstants";
import { AuthService } from "services/auth";

const AuthHelper = {
	startTokenRefreshTimeout(dispatch: Dispatch<AnyAction>) {
		const interval_secs = Number(
			process.env.REACT_APP_REFRESH_ACCESS_TOKEN_SECONDS || 60
		);
		const authService = new AuthService();
		setTimeout(() => {
			authService.refreshLogin(dispatch);
		}, interval_secs * 1000);
	},
	getLoginErrorStatus(p_status: number) {
		return String(
			Constants.loginStatus.find(
				(item: { label: number }) => item.label === p_status
			)?.value
		);
	}
};

export default AuthHelper;
