import { IconProps } from "./types";

export default function IcoGroup({
	color = "#1D1D1D",
	size = "32"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.667 26.7583V21.9583M16.667 21.9583C16.667 20.909 15.7716 20.0583 14.667 20.0583H6.66699C5.56242 20.0583 4.66699 20.909 4.66699 21.9583V25.7583M16.667 21.9583V20.0583C16.667 19.009 17.5624 18.1583 18.667 18.1583H26.667C27.7716 18.1583 28.667 19.009 28.667 20.0583V23.8583M14.667 12.4583C14.667 14.557 12.8761 16.2583 10.667 16.2583C8.45785 16.2583 6.66699 14.557 6.66699 12.4583C6.66699 10.3596 8.45785 8.6583 10.667 8.6583C12.8761 8.6583 14.667 10.3596 14.667 12.4583ZM26.667 10.5583C26.667 12.657 24.8761 14.3583 22.667 14.3583C20.4579 14.3583 18.667 12.657 18.667 10.5583C18.667 8.45962 20.4579 6.7583 22.667 6.7583C24.8761 6.7583 26.667 8.45962 26.667 10.5583Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
