import colors from "colors";
import { IconProps } from "./types";

export default function IcoCheck({
	color = colors.neutral["high-pure-50"],
	viewBox = "0 0 24 25",
	size = "25"
}: Readonly<IconProps>) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 12.5C3 7.52944 7.02944 3.5 12 3.5C16.9706 3.5 21 7.52944 21 12.5C21 17.4706 16.9706 21.5 12 21.5C7.02944 21.5 3 17.4706 3 12.5Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.5 12.8L11.1 15.5L16.5 9.5"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
