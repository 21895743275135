export default function IcoHeaderInfoWarning() {
	return (
		<svg
			width="160"
			height="160"
			viewBox="0 0 160 160"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M68.5972 25.7331L12.1305 120C10.9663 122.016 10.3503 124.302 10.3438 126.63C10.3373 128.958 10.9405 131.247 12.0934 133.27C13.2463 135.293 14.9087 136.978 16.9153 138.159C18.9218 139.339 21.2025 139.974 23.5305 140H136.464C138.792 139.974 141.073 139.339 143.079 138.159C145.086 136.978 146.748 135.293 147.901 133.27C149.054 131.247 149.657 128.958 149.651 126.63C149.644 124.302 149.028 122.016 147.864 120L91.3972 25.7331C90.2087 23.7738 88.5354 22.1538 86.5385 21.0296C84.5417 19.9054 82.2888 19.3148 79.9972 19.3148C77.7056 19.3148 75.4527 19.9054 73.4559 21.0296C71.4591 22.1538 69.7857 23.7738 68.5972 25.7331V25.7331Z"
				stroke="#F6C643"
				strokeWidth="4"
				strokeLinecap="square"
			/>
			<path
				d="M80 60V86.6667"
				stroke="#F6C643"
				strokeWidth="4"
				strokeLinecap="round"
			/>
			<path
				d="M80 113.333H80.0667"
				stroke="#F6C643"
				strokeWidth="4"
				strokeLinecap="round"
			/>
		</svg>
	);
}
