import AuthTokenHelper from "helpers/AuthTokenHelper";

function parseJwt(token: string) {
	const tokenData = JSON.parse(atob(token.split(".")[1]));
	return tokenData;
}

interface BackofficeUserInfo {
	authorities: string[];
	email: string;
	name: string;
}

export default function useBackofficeUserToken():
	| BackofficeUserInfo
	| undefined {
	const token = AuthTokenHelper.getAuthToken();
	if (!token) return undefined;
	const tokenInfo: BackofficeUserInfo | undefined = parseJwt(token);
	if (tokenInfo?.authorities.includes("ROLE_ADMIN")) {
		return tokenInfo;
	}
	return undefined;
}
