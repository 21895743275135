import colors from "colors";
import { IconProps } from "./types";

export default function IcoBed({
	color = colors.neutral["low-pure-500"],
	size = "16"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M2 3V10.5M2 10.5V13M2 10.5H14M14 13V10.5M14 10.5V7.75C14 6.64543 13.1046 5.75 12 5.75H7.25V10.5M5.75 7.625C5.75 8.24632 5.24632 8.75 4.625 8.75C4.00368 8.75 3.5 8.24632 3.5 7.625C3.5 7.00368 4.00368 6.5 4.625 6.5C5.24632 6.5 5.75 7.00368 5.75 7.625Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
