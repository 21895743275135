import colors from "colors";
import { IconProps } from "./types";

export default function IcoBagShopping({
	color = colors.neutral["low-pure-500"],
	size = "16"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M10.25 6.5V3C10.25 2.44772 9.80228 2 9.25 2H6.75C6.19772 2 5.75 2.44772 5.75 3V6.5M3 5H13V13C13 13.5523 12.5523 14 12 14H4C3.44772 14 3 13.5523 3 13V5Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
