import { IconProps } from "./types";

export default function IcoChevronDown({
	color = "#0D0D0D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
				fill={color}
			/>
		</svg>
	);
}
