function bool(name: string) {
	return process.env[name]?.toLocaleLowerCase() === "true";
}

const baseConfig = {
	appVersion: process.env.REACT_APP_VERSION ?? "1",
	baseURL: `${process.env.REACT_APP_API_URL}/api`,
	imageURL: process.env.REACT_APP_IMAGE_SOURCE_URL,
	tenantId: process.env.REACT_APP_TENANT_ID ?? "",
	baseURLDomain: process.env.REACT_APP_BASE_URL_DOMAIN ?? "",
	landingPageEnabled: bool("REACT_APP_LANDING_PAGE_ENABLED") ?? false,
	b2cUrl: process.env.REACT_APP_B2C_URL,
	b2cClientId: process.env.REACT_APP_B2C_CLIENT_ID
};

export { baseConfig };
