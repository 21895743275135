import { CookieHelper } from "helpers";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";

export default function CookieBar() {
	const [showCookieConsent, setShowCookieConsent] = useState(true);

	useEffect(() => {
		const consent = CookieHelper.getCookies("cookieConsent");
		if (!consent) {
			setShowCookieConsent(true);
		}
	}, []);

	if (!showCookieConsent) return null;

	return (
		<CookieConsent
			style={{
				background: "#ffffff",
				color: "#000000",
				fontSize: "14px",
				padding: "10px"
			}}
			buttonStyle={{
				backgroundColor: "rgba(29, 29, 29, 1)",
				color: "rgba(255, 255, 255, 1)",
				fontSize: "0.875rem",
				borderRadius: "0.25rem",
				paddingLeft: "1.5rem",
				paddingRight: "1.5rem",
				paddingTop: "0.75rem",
				paddingBottom: "0.75rem"
			}}
			buttonText="OK"
			cookieName="cookieConsent"
			expires={365}
		>
			<>
				Utilizamos cookies para oferecer uma melhor experiência de navegação. Ao
				continuar acessando o site, você concorda com a nossa{" "}
				<a
					href="https://rodovias.grupoccr.com.br/politica-de-cookies/"
					className=""
				>
					<u>política de cookies</u>
				</a>{" "}
				e{" "}
				<a href="https://rodovias.grupoccr.com.br/politica-de-privacidade/">
					<u>política de privacidade</u>
				</a>
				.
			</>
		</CookieConsent>
	);
}
