import { IconProps } from "./types";

export default function IcoVector({
	size = "40",
	color = "#ECECED"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={parseInt(size, 10) / 5}
			viewBox="0 0 40 8"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.2869 7.92194C19.7576 8.02602 20.2424 8.02602 20.7131 7.92194L38.5381 3.98107C39.5227 3.76337 40.1613 2.70453 39.9643 1.61608C39.7674 0.52762 38.8096 -0.178274 37.8249 0.0394177L20 3.98029L2.17507 0.0394177C1.19043 -0.178274 0.232585 0.52762 0.0356578 1.61608C-0.161269 2.70453 0.477295 3.76337 1.46193 3.98107L19.2869 7.92194Z"
				fill={color}
			/>
		</svg>
	);
}
