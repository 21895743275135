import { IconProps } from "./types";

export default function IcoTrash(props: Readonly<IconProps>): JSX.Element {
	const { size = 24 } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.3333 7.16667L16.3636 20.5H7.63636L6.66667 7.16667M8.70703 7.16667V5.83333C8.70703 5.09695 9.30399 4.5 10.0404 4.5H13.9619C14.6983 4.5 15.2953 5.09695 15.2953 5.83333V7.16667M4 7.16667H20"
				stroke="#1D1D1D"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
