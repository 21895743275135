import { IconProps } from "./types";

export default function IcoMenu({ color = "#7835E3", size = "24" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 12H17"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="square"
			/>
			<path d="M3 6H21" stroke={color} strokeWidth="2" strokeLinecap="square" />
			<path
				d="M3 18H21"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
}
