import { ComplexIconProps } from "./types";

export default function IcoMarkedCheckbox({
	fillColor = "#1D1D1D",
	color = "#FFFFFF",
	size = "24"
}: ComplexIconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
				fill={fillColor}
			/>
			<path
				d="M6 4.5H18V3.5H6V4.5ZM19.5 6V18H20.5V6H19.5ZM18 19.5H6V20.5H18V19.5ZM4.5 18V6H3.5V18H4.5ZM6 19.5C5.17157 19.5 4.5 18.8284 4.5 18H3.5C3.5 19.3807 4.61929 20.5 6 20.5V19.5ZM19.5 18C19.5 18.8284 18.8284 19.5 18 19.5V20.5C19.3807 20.5 20.5 19.3807 20.5 18H19.5ZM18 4.5C18.8284 4.5 19.5 5.17157 19.5 6H20.5C20.5 4.61929 19.3807 3.5 18 3.5V4.5ZM6 3.5C4.61929 3.5 3.5 4.61929 3.5 6H4.5C4.5 5.17157 5.17157 4.5 6 4.5V3.5Z"
				fill={fillColor}
			/>
			<path
				d="M8 12.3L11.2 15L16 9"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
