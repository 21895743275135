const LocalStorageHelper = {
	internalToken: "internal_token",

	setInternalToken(value: string) {
		localStorage.setItem(LocalStorageHelper.internalToken, value);
	},

	removeInternalToken() {
		localStorage.removeItem(LocalStorageHelper.internalToken);
	},

	getInternalToken() {
		return localStorage.getItem(LocalStorageHelper.internalToken);
	}
};

export default LocalStorageHelper;
