import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import { IcoArrowDown, IcoCloseModal } from "assets/icons";
import colors from "colors";
import { StringHelper } from "helpers";
import { useEffect, useState } from "react";
import "./styles.scss";
import { SideMenuProps } from "./types";

export default function SideMenu({
	isVisible,
	locations,
	onClose
}: SideMenuProps) {
	const [listOpen, setListOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!isVisible) setListOpen(false);
	}, [isVisible]);
	const handleToggleListOpen = () => {
		setListOpen(!listOpen);
	};
	return (
		<div className="container-sidemenu">
			<AnimatePresence>
				{isVisible && (
					<motion.div
						initial={{ x: "-100%" }}
						animate={{ x: 0 }}
						exit={{ x: "-100%" }}
						transition={{ duration: 0.2, ease: "easeInOut" }}
						className="content"
					>
						<div className="icon-close" onClick={onClose}>
							<IcoCloseModal color={colors.neutral["low-pure-500"]} />
						</div>
						<div className="wrapper-menu">
							<ul>
								<li>
									<Link to="/" onClick={onClose}>
										<h1>Home</h1>
									</Link>
								</li>
								{locations && (
									<li>
										<div className="list-toggle" onClick={handleToggleListOpen}>
											<h1>Localidades</h1>
											<div
												className={`icon-toggle ${listOpen ? "active" : ""}`}
											>
												<IcoArrowDown
													size="22"
													color={colors.neutral["low-pure-500"]}
												/>
											</div>
										</div>
										<div
											className="list-wrapper"
											style={{
												maxHeight: listOpen ? `${locations.length * 64}px` : "0"
											}}
										>
											{locations.map((location) => (
												<Link
													to={`localidade/${StringHelper.slugify(
														location.name
													)}`}
													onClick={onClose}
													key={`location-${location.id}`}
												>
													{location.name}
												</Link>
											))}
										</div>
									</li>
								)}
								<li>
									<Link to="/parceiro" onClick={onClose}>
										<h1>Seja um parceiro</h1>
									</Link>
								</li>
							</ul>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
			{isVisible && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.2, ease: "easeInOut" }}
					className="overlay"
				>
					&nbsp;
				</motion.div>
			)}
		</div>
	);
}
