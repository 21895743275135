import { BenefitTypeProps } from "pages/backoffice/Partners/Benefit/types";
import { BaseModel } from "./BaseModel";
import { BenefitProps } from "./types";

export class Benefit extends BaseModel {
	id: string;
	active?: boolean;
	archived?: boolean;
	archivingDate?: string;
	name?: string;
	description: string;
	benefitType?: BenefitTypeProps;
	startDate?: string;
	endDate?: string;
	couponLimitAmount?: number;
	couponsAlreadyCreated?: number;
	image?: string;
	location?: string;
	categories?: { name: string; id: string }[];

	constructor(data: BenefitProps) {
		super(data);
		this.id = data.id;
		this.active = data?.active;
		this.archived = data?.archived;
		this.archivingDate = data?.archivingDate;
		this.name = data?.name || data?.partner?.name;
		this.description = data.description;
		this.benefitType = data?.benefitType;
		this.startDate = data?.startDate;
		this.endDate = data?.endDate;
		this.couponLimitAmount = data?.couponLimitAmount;
		this.couponsAlreadyCreated = data?.couponsAlreadyCreated;
		this.image = data?.image;
		this.location = data?.partner?.addresses?.[0]?.city;
		this.categories = data?.partner?.categories;
	}
}
